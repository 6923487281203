(function ($) {
    $(document).ready(function () {
        var mySwiper = new Swiper('.swiper-gallery-container', {
            // Navigation arrows
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 4,
            spaceBetween: 30,

            breakpoints: {

                1024: {
                    slidesPerView: 4,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                425: {
                    slidesPerView: 1,
                },
            }
        });
        var imageSwiper = new Swiper('.swiper-image-container', {
            // Navigation arrows
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            slidesPerView: 1,
            spaceBetween: 30,
            autoplay: 5000,
            pagination: '.swiper-pagination',
            loop: false,
            paginationType: 'bullets',
            paginationClickable: true
        });

        /** todo: make dynamic */
        if($('body').hasClass('page-id-79')) {
            // var logoSwiper = new Swiper('.swiper-logo-container', {
            //     // Navigation arrows
            //     nextButton: '',
            //     prevButton: '',
            //     slidesPerView: 1,
            //     spaceBetween: 30,
            //     autoplay: false
            // });
            //
            // $('#menu-item-475').on('hover', function () {
            //     imageSwiper.slideTo(1);
            // });
            // $('#menu-item-476').on('hover', function () {
            //     imageSwiper.slideTo(2);
            // });
            // $('#menu-item-499').on('hover', function () {
            //     imageSwiper.slideTo(0);
            // });
        }

        $("img").unveil();
    });
    $(window).resize(function () {
        $("img").unveil();
    });

}(jQuery));
